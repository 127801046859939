.date-range-selector {
    display: flex;
    align-items: center;
    gap: 20px; /* Espaçamento entre os campos */
  }
  
  .date-picker-container {
    display: flex;
    flex-direction: column;
  }
  
  .date-picker-container label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .react-datepicker__input-container input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  