@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Dosis Bold';
    src: url('./assets/fonts/dosis-bold-webfont.eot') format('eot'),
        url('./assets/fonts/dosis-bold-webfont.woff') format('woff'),
        url('./assets/fonts/dosis-bold-webfont.ttf') format('truetype'),
        url('./assets/fonts/dosis-bold-webfont.svg') format('svg');   
}

@font-face {
    font-family: 'Dosis Regular';
    src: url('./assets/fonts/dosis-regular-webfont.eot') format('eot'),
        url('./assets/fonts/dosis-regular-webfont.woff') format('woff'),
        url('./assets/fonts/dosis-regular-webfont.ttf') format('truetype'),
        url('./assets/fonts/dosis-regular-webfont.svg') format('svg');
}

@font-face{
    font-family: 'Dosis Light';
    src: url('./assets/fonts/dosis-light-webfont.eot') format('eot'),
        url('./assets/fonts/dosis-light-webfont.woff') format('woff'),
        url('./assets/fonts/dosis-light-webfont.ttf') format('truetype'),
        url('./assets/fonts/dosis-light-webfont.svg') format('svg');
}

@font-face{
    font-family: 'Inter Regular';
    src: 
        url('./assets/fonts/Inter-Regular.woff') format('woff'),
        url('./assets/fonts/Inter-Regular.woff2') format('woff2');
}

@layer base {
    html {
        font-family: Dosis Light, system-ui, sans-serif;
        font-color: #333333;
    }
}